/**
 *  SEO component
 *  Because of how Gatsby generates the pages, this component won't work during dev
 *  In order to test this component out run 'yarn build' and then 'yarn serve'
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import uuid from 'uuid/v1';

const googleAnalytics = (lang, pagePath) => {
  const gaLangIds = {
    'en-us': process.env.GATSBY_GA_ID_US,
    'en-gb': process.env.GATSBY_GA_ID_GB,
    'en-ca': process.env.GATSBY_GA_ID_CA,
    'en-au': process.env.GATSBY_GA_ID_AU,
    'es-mx': process.env.GATSBY_GA_ID_MX,
  };

  const correctLangID = gaLangIds[lang];

  return [
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${correctLangID}`}
      key={uuid()}
    />,
    <script key={uuid()}>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', '${correctLangID}', {
        'anonymize_ip' : true,
        'page_path': "${lang}${pagePath}"
      });
    `}
    </script>,
  ];
};

const facebookPixel = [
  <script id="cidsyncjs" src="https://pixel-static.spotify.com/sync.min.js" async key={uuid()} />,
  <noscript key={uuid()}>
    {`
    <img
      id="cidmgmt"
      src="https://pixel.spotify.com/v1/sync?nojs=1"
      style="display: none;"
      width="0"
      height="0"
    />
  `}
  </noscript>,
];

function SEO({ title, description, pageUrl, twitterUsername, lang }) {
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${process.env.GATSBY_DOMAIN_NAME}${pageUrl}`,
        },
        {
          property: 'og:image',
          content: `${process.env.GATSBY_DOMAIN_NAME}/social/facebook.jpg`,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:site',
          content: `@${twitterUsername}`,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          property: 'twitter:image',
          content: `${process.env.GATSBY_DOMAIN_NAME}/social/twitter.jpg`,
        },
      ]}
    >
      {googleAnalytics(lang, pageUrl)}
      {process.env.GATSBY_ENV === 'production' && facebookPixel}
    </Helmet>
  );
}

SEO.defaultProps = { description: '' };

SEO.propTypes = {
  description: PropTypes.string,
  twitterUsername: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SEO;
