import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '../styles/style.css';

import CookieBar from './cookieBar';

const Container = styled.div`
  height: 100vh;
  position: relative;
  overflow-x: hidden;
`;

const Layout = ({ children }) => (
  <Container>
    <main>{children}</main>
    <CookieBar />
  </Container>
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  // cms: PropTypes.objectOf(PropTypes.any).isRequired,
};
