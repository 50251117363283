/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
// import PropTypes from "prop-types"
import styled from 'styled-components';
import Cookies from 'js-cookie';
import gsap, { Sine } from 'gsap';

const CookierWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 150;
  width: 100%;
  background: black;
  border-top: 1px solid #545454;
  transform: translateY(100%);
`;

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
  padding: 1.3625rem 1.25rem 1.175rem;
  position: relative;
  box-sizing: border-box;
`;

const Text = styled.p`
  color: #545454;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  font-family: 'CircularSpotifyText-Book';

  @media (min-width: 640px) {
    padding-right: 12.5rem;
    text-align: left;
  }

  a {
    color: #878787;
    text-decoration: underline;
    transition: color 0.3s ease-in-out;
    white-space: nowrap;

    &:hover,
    &:active,
    &:focus {
      color: #1ed760;
      text-decoration: underline;
    }
  }
`;

const CloseButton = styled.button`
  width: 100%;
  color: #1ed760;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: transparent;
  margin: 16px 0 0;
  border: 0;

  @media (min-width: 640px) {
    position: absolute;
    top: 0.9375rem;
    right: 1.25rem;
    width: auto;
    box-shadow: rgb(30, 215, 96) 0rem 0.25rem 0rem 0rem inset;
    padding-top: 0.625rem;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

const CookieBar = () => {
  const [cookiesAccepted, setcookiesAccepted] = useState(!!Cookies.get('cookiesAccepted'));

  const updateCookies = event => {
    event.preventDefault();
    Cookies.set('cookiesAccepted', true, { expires: 365 });
    setcookiesAccepted(true);

    gsap.to('.cookierBar_anim', 0.4, {
      y: '100%',
      ease: Sine.easeInOut,
    });
  };

  const cookieText =
    'We and <a href="https://www.spotify.com/legal/cookies-vendor-list/" target="_blank" rel="noopener noreferrer" data-event-label="our partners cookiebar">our partners</a> use cookies to personalize your experience, to show you ads based on your interests, and for measurement and analytics purposes. By using our website and our services, you agree to our use of cookies as described in our <a href="https://www.spotify.com/legal/cookies-policy/" target="_blank" rel="noopener noreferrer" data-event-label="our policy cookiebar">Cookie Policy.</a>';

  useEffect(() => {
    if (!cookiesAccepted) {
      gsap.to('.cookierBar_anim', 0.4, {
        y: 0,
        ease: Sine.easeInOut,
      });
    }
  }, [cookiesAccepted]);

  return (
    <CookierWrapper className="cookierBar_anim">
      <InnerWrapper>
        <Text dangerouslySetInnerHTML={{ __html: cookieText }} />
        <CloseButton onClick={e => updateCookies(e)} data-event-label="close cookie bar">
          ok i understand
          {/* cms.cookiebar.button */}
        </CloseButton>
      </InnerWrapper>
    </CookierWrapper>
  );
};

// CookieBar.propTypes = { cms: PropTypes.objectOf(PropTypes.any) }

export default CookieBar;
