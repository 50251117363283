import axios from 'axios';

class RACApi {
  constructor(options) {
    this.defaults = {
      baseURL: process.env.GATSBY_API_URL,
      url: '',
      method: 'get',
      key: process.env.GATSBY_API_KEY,
    };

    this.settings = { ...this.defaults, ...options };
  }

  async submit(data) {
    const config = {
      url: this.settings.url,
      baseURL: this.settings.baseURL,
      method: this.settings.method,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.settings.key}`,
        'Content-Type': 'application/json',
      },
    };

    if (data) {
      config.params = data;
    }

    try {
      const response = await axios(config);
      if (typeof window !== 'undefined') {
        console.log(
          `%c /${this.settings.url} response 🔥 `,
          'background: #222; color: #bada55',
          response,
        );
      }

      return response.data;
    } catch (error) {
      console.error(
        `%c /${this.settings.url} error 😞 `,
        'background: #222; color: #ff5555',
        error,
      );

      return {};
    }
  }
}

export default RACApi;
