import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as ImagesLoaded from 'imagesloaded';
import Svg from 'react-inlinesvg';
import { Sine, Power4, TimelineMax } from 'gsap';

/* SVG */
import loaderLogo from '../images/svgs/loader_logo.svg';
import loaderCali from '../images/svgs/loader_cali.svg';

const LoadingWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  background: linear-gradient(270deg, #70c6bf 0%, #53b8d0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CaliDreamBig = styled(Svg)`
  height: 40px;
  width: 101.41px;

  @media (min-width: 768px) {
    height: 3.75rem;
    width: 9.5075rem;
  }
`;

const Container = styled.div`
  margin: 50px 0px 131px;

  @media (min-width: 768px) {
    margin: 163px 0px 203px;
  }

  @media (min-width: 1024px) {
    margin: 2.5625rem 0px 5.125rem;
  }
`;

const Logo = styled(Svg)`
  height: 220px;
  width: 174.17px;

  @media (min-width: 768px) {
    height: 27.375rem;
    width: 21.5625rem;
  }
`;

const LoadingBar = styled.div`
  position: relative;
  height: 0.1875rem;
  width: 280px;
  background: rgba(61, 70, 67, 0.2);
  border-radius: 0.09375rem;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 32.5rem;
  }

  .progress {
    width: 100%;
    height: 100%;
    background: rgba(61, 70, 67, 1);
    transform: translateX(-100%);
    border-radius: 0.09375rem;
  }
`;

const Loader = ({ setLoader, loaderTime }) => {
  /* loaderTime comes through playlist index to give delay for playlist to generate */
  const checkTime = () => {
    let time;

    if (!loaderTime) {
      time = 3.5;
    } else {
      time = 9;
    }

    return time;
  };

  const loaderAnim = () => {
    const tl = new TimelineMax({ onComplete: () => setLoader(false) });

    tl.to('.progress', {
      duration: checkTime(),
      ease: Sine.easeOut,
      x: 0,
      delay: 0.5,
    }).to('.fade', 0.6, {
      opacity: 0,
      ease: Power4.easeInOut,
      // delay: 4000,
    });
  };

  useEffect(() => {
    /* Load images before any content then animation */
    ImagesLoaded('.image-load', () => {
      loaderAnim();
    });
  }, []);

  return (
    <LoadingWrapper className="fade">
      <CaliDreamBig src={loaderCali} className="image-load" />
      <Container>
        <Logo src={loaderLogo} className="image-load" />
      </Container>
      <LoadingBar>
        <div className="progress" />
      </LoadingBar>
    </LoadingWrapper>
  );
};

export default Loader;
