import React from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';

/* SVG */
import logo from '../images/svgs/landing_logo.svg';

const HeaderWrapper = styled.div`
  height: 90px;
  width: 188px;

  @media (min-width: 768px) {
    height: 7.5rem;
    width: 15.125rem;
  }
`;

const Logo = styled(Svg)`
  height: 100%;
  width: 100%;
`;

const Header = () => (
  <HeaderWrapper className="start_fade">
    <Logo src={logo} />
  </HeaderWrapper>
);

export default Header;
