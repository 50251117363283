import React from 'react';
import styled from 'styled-components';
import Svg from 'react-inlinesvg';
// import PropTypes from 'prop-types'

/* SVG */
import spotifyLogo from '../images/svgs/listen_on_spotify.svg';

const FooterWrapper = styled.footer`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80px;
  padding: 25px 0px;
  z-index: 4;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  font-family: 'Circular Spotify Text', Helvetica, Arial, sans-serif;
  background: #ffffff;

  @media (min-width: 768px) {
    padding: 13px 5px;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    padding: 0px;
  }
`;
const Spotify = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    margin-right: 2.5rem;
  }
`;

const SpotifyLogoLink = styled.a`
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

const SpotifyLink = styled.a`
  margin: 0px 6px;
  color: #000;
  font-size: 0.75rem;
  transition: opacity 0.3s ease-in-out;
  text-decoration: none;

  @media (min-width: 768px) {
    margin: 0px 0.5625rem;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const SpotifyLinks = styled.ul`
  display: flex;
  margin: 0;
  list-style-type: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 6.25rem;
  padding: 0px 0.5rem;

  @media (min-width: 1024px) {
    margin-bottom: 0.0625rem;
  }
`;

const Logo = styled(Svg)`
  width: 100%;

  * {
    fill: #000;
  }
`;

const Brand = styled.div`
  position: relative;
  font-size: 0.75rem;

  &:after {
    position: absolute;
    bottom: -16px;
    left: 50%;
    width: 20px;
    height: 1px;
    background: #000;
    content: '';
    transform: translate(-50%, 0);

    @media (min-width: 1024px) {
      bottom: 50%;
      left: -1.53125rem;
      width: 0.0625rem;
      height: 1rem;
      transform: translate(0, 50%);
    }
  }
`;

const BrandLink = styled.a`
  color: #000;
  transition: opacity 0.3s ease-in-out;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Trend Slab';
  font-size: 0.625rem;
  line-height: 0.8125rem;

  &:hover {
    opacity: 0.7;
  }

  /* &:hover,
  &:active,
  &:focus {
    color: black;
  } */
`;

const Footer = () => {
  const links = [
    {
      text: 'Legal',
      label: 'footer legal',
      url: 'https://www.spotify.com/legal/end-user-agreement/',
    },
    {
      text: 'Privacy',
      label: 'footer privacy',
      url: 'https://www.spotify.com/privacy/',
    },
    {
      text: 'Cookies',
      label: 'footer cookies',
      url: 'https://www.spotify.com/legal/cookies-policy/',
    },
  ];

  return (
    <FooterWrapper>
      <Spotify>
        <SpotifyLogoLink
          href="https://www.spotify.com/us/"
          target="_blank"
          rel="noopener noreferrer"
          data-event-label="footer spotify"
        >
          <LogoContainer>
            <Logo src={spotifyLogo} />
          </LogoContainer>
        </SpotifyLogoLink>
        <SpotifyLinks>
          {links.map(link => (
            <li key={link.text}>
              <SpotifyLink
                href={link.url}
                data-event-label={link.label}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.text}
              </SpotifyLink>
            </li>
          ))}
        </SpotifyLinks>
      </Spotify>
      <Brand>
        <BrandLink
          href="https://www.visitcalifornia.com/"
          target="_blank"
          rel="noopener noreferrer"
          data-event-label="footer brand link"
        >
          www.visitcalifornia.com
        </BrandLink>
      </Brand>
    </FooterWrapper>
  );
};

// Footer.propTypes = { cmsContent: PropTypes.objectOf(PropTypes.any).isRequired };

export default Footer;
